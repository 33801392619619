import { WalletTgSdk } from '@uxuycom/web3-tg-sdk'
import Web3 from 'web3'

// 创建一个封装 UXUY SDK 的连接器
export class UxuyTgConnector {
  provider: any

  walletTgSdk: WalletTgSdk

  web3: Web3

  constructor() {
    // 初始化 UXUY 钱包 SDK
    this.walletTgSdk = new WalletTgSdk({ injected: true })
    // 通过 UXUY SDK 获取 Ethereum provider
    this.provider = this.walletTgSdk.ethereum
    // 初始化 Web3 实例
    this.web3 = new Web3(this.provider)
  }

  // 连接钱包
  async activate() {
    try {
      const accounts = await this.provider.request({ method: 'eth_requestAccounts' })
      const chainId = await this.provider.request({ method: 'eth_chainId' })
      return { accounts, chainId }
    } catch (error) {
      console.error('Failed to connect UXUY Wallet:', error)
      throw error
    }
  }

  // 提示用户切换到 BSC 网络
  async switchNetworkToBSC() {
    try {
      await this.provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x38' }], // 56 为 BSC 主网的十六进制链 ID
      })
      console.log("===== switch bsc success!")
    } catch (switchError) {
      console.error('Failed to switch network:', switchError)
      // 显示提示，要求用户手动切换网络
      throw new Error('Please switch to the Binance Smart Chain network.')
    }
  }

  // 断开钱包连接
  deactivate() {
    this.provider = null
  }
}

export const uxuyTgConnectorInstance = new UxuyTgConnector()
