import { useEffect, useState } from 'react'

// 定义 CAKE 的地址
const CAKE_ADDRESS = '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82'


type DexScreenerApiResponse = {
  pairs: Array<{
    priceUsd: string
  }>
}
const api = `https://api.dexscreener.com/latest/dex/tokens/$CAKE_ADDRESS` // CAKE 合约地址


// type ApiResponse = {
//   updated_at: string
//   data: {
//     [key: string]: {
//       name: string
//       symbol: string
//       price: string
//       price_BNB: string
//     }
//   }
// }


const useGetPriceData = () => {
  // const [data, setData] = useState<ApiResponse | null>(null)
  // const [priceData, setPriceData] = useState<number | null>(null)
  const [cakePriceUsd, setCakePriceUsd] = useState<number | null>(null)


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(api)
        const res: DexScreenerApiResponse = await response.json()
        console.log(res)

        // DexScreener 返回的价格在 `pairs` 数组中，我们取第一个配对的 `priceUsd`
        if (res.pairs && res.pairs.length > 0) {
          setCakePriceUsd(parseFloat(res.pairs[0].priceUsd))
        }
      } catch (error) {
        console.error('Unable to fetch price data from DexScreener API:', error)
      }
    }

    fetchData()
  }, [])

  return cakePriceUsd
}

export default useGetPriceData
